<template>
  <!-- Generated using model-view-update.mustache -->
  <div class="update-entity">
    <CForm
      v-if="isReady && isOneStepEdit"
      ref="updateTag"
      v-on:submit.prevent="updateSettings(true)"
      novalidate
      class="update-tag-form"
    >
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.editPage.title"
        submit-title="update"
      />
      <FormBuilder
        :list="formList"
        :isCreateHeader="false"
        @updated="updateFormData"
        :page="{
          title: texts.editPage.title,
          info: descriptions,
          mode: 'update',
        }"
      />
    </CForm>
    <div class="edit-page__header" v-if="isReady && !isOneStepEdit">
      <div class="wrapper_edit_forms content d-flex justify-content-between">
        <div class="zq-create-page-title-wrapper">
          <h3 class="zq-child-title-text zq-edit-title">Edit {{entityName}}</h3>
        </div>
        <ActionsForEdit
          @isShowDropdown="isShowDropdown"
          :isShowUpdate="false"
        />
      </div>
      <div class="tabs_wrapper">
        <div
          @click="showTab('settings')"
          :class="[{active: tabName === 'settings', notSaved:  !isSettingsDataSaved}, tabClasses]"
        >
          Settings
          <div class="edit-tooltip-wrapper" v-if="!isSettingsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div
              class="tooltip-old bs-tooltip-old-auto fade show"
              role="tooltip"
              aria-hidden="true"
              x-placement="right"
            >
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="ruleModels.includes(model)"
          @click="showTab('rules')"
          :class="[{active: tabName === 'rules', notSaved:  !isRulesDataSaved}, tabClasses]"
        >
          Rules
          <div class="edit-tooltip-wrapper" v-if="!isRulesDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="rewardModels.includes(model)"
          @click="showTab('rewards')"
          :class="[{active: tabName === 'rewards', notSaved:  !isRewardsDataSaved}, tabClasses]"
        >
          Rewards
          <div class="edit-tooltip-wrapper" v-if="!isRewardsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="translatableFields.length"
          @click="showTab('translations')"
          :class="[{active: tabName === 'translations', notSaved:  !isTranslationsDataSaved}, tabClasses]"
        >
          Translations
          <div class="edit-tooltip-wrapper" v-if="!isTranslationsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-content content position-relative" v-if="isReady && !isOneStepEdit">
      <div v-if="tabName === 'settings' && isReady">
        <CCardHeader>
          <div class="mb-4 d-flex justify-content-between align-items-center">
            <strong class="title">Edit Settings</strong>
            <div class="message" v-if="!isSettingsDataSaved">Data not saved!</div>
            <CButton
              class="action-create-button zq--responsive-button__common"
              type="submit"
              @click="updateSettings(false)"
            >
              Update Settings
            </CButton>
          </div>
        </CCardHeader>
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="settingsCollapsed = !settingsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Settings</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="settingsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="settingsCollapsed" :duration="400">
                <CCardBody>
                  <CForm
                    ref="updateWalletType"
                    v-on:submit.prevent="updateWalletType"
                    novalidate
                    class="update-walletType-form"
                  >
                    <FormBuilder
                      :list="formList"
                      :isCreateHeader="false"
                      @updated="updateFormData"
                      :page="{
                        title: texts.editPage.title,
                        info: descriptions,
                        mode: 'update',
                      }"
                    />
                  </CForm>
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow v-if="dependantOnData">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="dependantOnCollapsed = !dependantOnCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit DependantOn</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="dependantOnCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="dependantOnCollapsed" :duration="400">
                <CCardBody>
                  <EditDependantOn
                    :dependantOnData="dependantOnData"
                    :isValid="true"
                    @updateDependantOnData="updateDependantOnData"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow  v-if="schedulingData">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="schedulingCollapsed = !schedulingCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Scheduling</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="schedulingCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="schedulingCollapsed" :duration="400">
                <CCardBody>
                  <EditScheduling
                    :schedulingData="schedulingData"
                    @updateSchedulingData="updateSchedulingData"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <PreviewSpiner v-if="!isReady" />
      <EditRules
        v-if="ruleModels.includes(model) && tabName === 'rules'"
        :entityId="entityId"
        :context="'walletType'"
        :notSavedRulesData="notSavedRulesData"
        @isRulesDataSaved="setRulesDataSaved"
        @notSavedRulesData="setNotSavedRulesData"
      />
      <EditRewards
        v-if="rewardModels.includes(model) && tabName === 'rewards'"
        :entityId="entityId"
        :entityType="'WalletType'"
        :notSavedTranslationsData="notSavedTranslationsData"
        :notSavedRewardsData="notSavedRewardsData"
        @isRewardsDataSaved="setRewardsDataSaved"
        @notSavedRewardsData="setNotSavedRewardsData"
      />
      <EditTranslations
        ref="editTranslations"
        v-if="modelFields.baseFields.translatableFields.length > 0 && tabName === 'translations'"
        :entityData="formData"
        :entityType="'WalletType'"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        :notSavedTranslationsData="notSavedTranslationsData"
        @isTranslationsDataSaved="setTranslationsDataSaved"
        @notSavedTranslationsData="setNotSavedTranslationsData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from "@/config";
import { walletTypes } from '@/config/descriptions/walletTypes';
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';
import { walletTypesTexts } from '@/config/pageTexts/walletTypes.json';
import walletTypeFields from '@/generated/ziqni/store/modules/walletTypes/fields';
import EditRules from '@/shared/components/supportModels/rules/EditRules';
import EditScheduling from '@/shared/components/supportModels/scheduling/EditScheduling';
import EditRewards from '@/shared/components/supportModels/rewards/EditRewards';
import EditTranslations from '@/shared/components/supportModels/translations/EditTranslations';
import EditDependantOn from '@/shared/components/supportModels/dependantOn/EditDependantOn';
import ActionsForEdit from '@/shared/components/ActionsForEdit';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import PreviewSpiner from '@/shared/UI/Spiner';
import CreateHeader from "@/shared/components/CreateHeader";

export default {
  name: 'EditWalletType',
  components: {
    EditTranslations,
    ActionsForEdit,
    EditRules,
    EditScheduling,
    EditRewards,
    EditDependantOn,
    ClCardArrow,
    PreviewSpiner,
    CreateHeader,
  },
  data() {
   return {
     model: 'walletType',
     entityId: this.$route.params.id,
     entityName: '',
     descriptions: {
       ...walletTypes.edit
     },
     texts: {
       ...walletTypesTexts
     },
     isShowDropdown: false,
     tabName: "settings",
     tabClasses: "btn edit-tab",
     selectLabel: formConfig.selectLabel,
     tagPlaceholder: formConfig.tagPlaceholder,
     showCMetaInputs: false,
     showCAdjustmentFactorsInputs: false,
     formData: null,
     accountType: [],
     options: ["Slots", "Casino", "Hockey"],
     idValidate: null,
     nameValidate: null,
     typeValidate: null,
     page: pageConfig.page,
     formList: [],
     translatableFields: [],
     translationsData: {},
     modelFields : {
       ...walletTypeFields,
     },
     schedulingData: null,
     dependantOnData: null,
     rewardModels: ['achievement'],
     ruleModels: ['achievement'],
     settingsCollapsed: true,
     dependantOnCollapsed: false,
     schedulingCollapsed: false,
     isReady: false,
     isOneStepEdit: false,
     isSettingsDataSaved: true,
     isRulesDataSaved: true,
     isRewardsDataSaved: true,
     isTranslationsDataSaved: true,
     notSavedText: 'Data not saved!',
     notSavedRulesData: {},
     notSavedTranslationsData: {},
     notSavedRewardsData: {},
   }
  },
  computed: {
    ...mapGetters('walletTypes', ['success', 'message', 'loading', 'walletTypes', 'walletType']),
    ...mapGetters('actionTypes', ['success', 'message', 'actionType', 'actionTypes']),
    ...mapGetters('theme', ['theme']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    created() {
      return dateFormate(this.formData.created)
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('walletTypes', [
      'handleGetWalletTypes_item',
      'handleUpdateWalletTypes'
    ]),
    ...mapActions('actionTypes', ['handleGetActionTypes_item', 'handleGetActionTypes']),
    initialize() {
      this.handleGetActionTypes([]);
      this.handleGetWalletTypes_item([this.$route.params.id],1,0)
        .then(data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name
          });

          let actionTypesIds = []
          if (data[0].actionTypeAdjustmentFactors) {
            actionTypesIds = data[0].actionTypeAdjustmentFactors.map((item) => {
              return item.actionTypeId
            })
          }

          this.query = actionTypesIds.length ? {"id": actionTypesIds.join()} : []; // just query for the ids!

          this.handleGetActionTypes_item(actionTypesIds);
          this.formList = fieldHelpers.prepareEditFormList(
            walletTypeFields,
            this.texts.editPage,
            this.descriptions,
            this.walletType
          );

          if (Object.keys(data[0]).includes('scheduling')) {
            this.schedulingData = cloneDeep(data[0].scheduling);
            if (this.schedulingData.startDate) {
              this.schedulingData.startDate = new Date(this.schedulingData.startDate).toISOString();
            }
            if (this.schedulingData.endDate) {
              this.schedulingData.endDate = new Date(this.schedulingData.endDate).toISOString();
            }
          }

          if (Object.keys(data[0]).includes('dependantOn')) {
            if (data[0].dependantOn) {
              this.dependantOnData = cloneDeep(data[0].dependantOn);
            } else {
              this.dependantOnData = {
                dependantOn: {
                  must: [],
                  mustNot: [],
                  should: [],
                  shouldMatchAtLeast: 1,
                },
                entityType: 'WalletType',
              }
            }
          }
        });
      this.translatableFields = walletTypeFields.baseFields.translatableFields;

      this.isOneStepEdit = !this.ruleModels.includes(this.model)
        && !this.rewardModels.includes(this.model)
        && !this.modelFields.baseFields.translatableFields.length;

      this.isReady = true;
    },
    showTab(name) {
      this.tabName = name;
    },
    setRulesDataSaved(val) {
      this.isRulesDataSaved = val;
    },
    setRewardsDataSaved(val) {
      this.isRewardsDataSaved = val;
    },
    setTranslationsDataSaved(val) {
      this.isTranslationsDataSaved = val;
    },
    setNotSavedRulesData(obj) {
      this.notSavedRulesData = obj;
    },
    setNotSavedTranslationsData(obj) {
      this.notSavedTranslationsData = obj;
    },
    setNotSavedRewardsData(obj) {
      this.notSavedRewardsData = obj;
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    updateDependantOnData(val) {
      if (!isEqual(this.dependantOnData.dependantOn, val.formData.dependantOn)) {
        let selectedIds = [];
        val.selectedData.forEach(item => selectedIds.push(item.id));
        this.dependantOnData.dependantOn = cloneDeep(val.formData.dependantOn);
        this.dependantOnData.dependantOn.must = this.dependantOnData.dependantOn.must.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.mustNot = this.dependantOnData.dependantOn.mustNot.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.should = this.dependantOnData.dependantOn.should.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.entityType = val.formData.entityType;
        this.isSettingsDataSaved = false;
      }
    },
    updateSchedulingData(val) {
      if (!isEqual(this.schedulingData, val)) {
        this.schedulingData = val;
        this.isSettingsDataSaved = false;
      }
    },
    updateSettings(isOneStepEdit = false) {
      delete this.formData.statusCode;
      delete this.formData.status;
      delete this.formData.achievementLiveStatus;
      delete this.formData.spaceName;
      delete this.formData.created;
      delete this.formData.productRefId;
      delete this.formData.rewardType;
      delete this.formData.constraints;
      delete this.formData.memberRefId;
      delete this.formData.key;

      const body = [this.formData];

      this.handleUpdateWalletTypes({updateWalletTypeRequestArray: body})
        .then(data => {
          this.isSettingsDataSaved = true;
          if (data.length && isOneStepEdit) {
            this.$router.push({
              name: 'PreviewWalletType',
              params: {
                id: data[0].id,
              }
            })
          }
        })
    }
  },
  watch: {
    walletType() {
      this.entityName = this.walletType.name
    },
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.update-entity {
  height: 100%;
  .card-header {
    background-color: #f3f3f3;
    & ~ .row:first-of-type {
      margin-top: 1rem;
    }
  }
  .card {
    &.zq--wizard-card {
      background-color: var(--zq-sub-bg);
    }
  }
  .edit-tooltip-wrapper {
    display: inline-block;
    position: relative;
    .tooltip-old {
      position: absolute;
      visibility: hidden;
      top: -4px;
      left: 16px;
    }
  }
  .multiselect {
    &--disabled {
      .multiselect__single {
        background: none;
      }
    }
  }
  .fa-question-circle-o:hover ~ .tooltip-old {
    visibility: visible !important;
  }
  .message {
    color: var(--zq-warn);
  }
  .notSaved {
    color: var(--zq-warn) !important;
  }
  .form-content {
    border-top: 0;
  }
  .edit-page__header {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--zq-main-bg);
  }
  .wrapper_edit_forms {
    margin-top: 20px;
    display: flex;
  }
  .edit-tab {
    width: 50%;
    height: 35px;
    cursor: pointer !important;
    border-radius: 12px 12px 0 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    text-align: left;
    &.active {
      border-bottom: solid 2px #007dfb;
    }
    &.btn {
      padding: 0.375rem 1rem;
      text-align: center;
    }
  }
  .tabs_wrapper {
    height: 100%;
    max-height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: 12px 12px 0 0;
  }
  & > .form-content {border-top: none}
  .update-entity-form {
    height: calc(100% - 50px);
    input::placeholder {
      font-style: italic;
    }
    .card {
      background-color: var(--zq-sub-bg);
    }
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      .form-control.is-valid {
        padding-right: 15px;
      }
      .custom-multi-select .multiselect__select {
        opacity: 0;
      }
      .adjustment-factors-data {
        .fa-question-circle-o {
          top: 10px;
          right: -20px;
        }
      }
      .search-icon {
        position: absolute;
        width: 30px !important;
        height: 47px !important;
        right: 15px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
      }
      .editor {
        min-height: 200px;
        height: 200px;
      }
      .fa-question-circle-o {
        position: absolute;
        top: 0;
        right: -10px;
      }
      .meta-data {
        min-width: 70px;
        .fa-question-circle-o {
          top: 10px;
        }
      }
    }
  }
  @include media-breakpoint-up('768px') {
    .edit-page__header {
      padding: 0;
    }
    .wrapper_edit_forms {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: -8px;
    }
    .tabs_wrapper {
      margin: 0 3rem;
      min-width: 375px;
      width: 47%;
      max-height: 54px;
    }
    .edit-tab {
      height: 54px;
      line-height: 42px;
      cursor: pointer !important;
      &:hover {
        color: #858585;
      }
    }
  }
}
</style>
